<template>
  <div class="flex-fill">
    <m-header/>
    <div class="d-flex m-0 p-0 h-100 align-item-strenth">
      <transition name="left-menu-open">
        <div class="left-menu bg-light fixed-top h-100 border-right" :class="{'left-menu-closed': !isLeftMenuOpened}">
          <left-menu/>
        </div>
      </transition>
      <div class="content-wrapper flex-fill" :class="{'no-left-margin': !isLeftMenuOpened}">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import MHeader from '@/components/common/Header'
import LeftMenu from '@/components/common/LeftMenu'

export default {
  created () {
    window.addEventListener('resize', this.onWindowResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.onWindowResize)
  },
  methods: {
    setupLeftMenu (width) {
      if (width <= this.$store.state.layout.leftMenuFullwidthThreshold) {
        this.$store.dispatch('layout/closeLeftMenu')
      } else {
        this.$store.dispatch('layout/openLeftMenu')
      }
    },
    onWindowResize (e) {
      this.setupLeftMenu(e.target.innerWidth)
    }
  },
  mounted () {
    this.setupLeftMenu(window.innerWidth)
  },
  computed: {
    ...mapGetters({
      isLeftMenuOpened: 'layout/isLeftMenuOpened'
    })
  },
  components: {
    MHeader,
    LeftMenu
  }
}
</script>

<style scoped lang="scss">
.content-wrapper {
  margin-top: 80px;
  margin-left: 400px;
  transition-duration: 0.15s;
  transition-property: margin-left;
}

.left-menu {
  overflow: hidden;
  width: 400px;
  transition-duration: 0.15s;
  transition-property: width;
  z-index: 1;
}

.left-menu-closed {
  width: 0 !important;
  transition-duration: 0.15s;
  transition-property: width;
}

.no-left-margin {
  margin-left: 0;
}

@media (max-width: 1200px) {
  .left-menu {
    width: 300px;
  }
  .content-wrapper {
    margin-left: 300px;
  }
}

@media (max-width: 991.98px) {
  .left-menu {
    z-index: 2;
  }
  .content-wrapper {
    margin-left: 0;
  }
}

@media (max-width: 400.98px) {
  .left-menu {
    width: 100%;
  }
}

.left-menu-open-enter-active, .left-menu-open-leave-active {
  transition: opacity .5s;
}
</style>

<style lang="scss">
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
}

.btn-link {
  color: inherit;
  text-decoration: none;

  &:hover, &:active, &:visited {
    text-decoration: none;
  }
}
</style>
