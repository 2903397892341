<template>
  <div class="pl-5 pr-5 left-menu-inner">
    <ul class="m-nav">
      <li class="m-nav-item" v-for="(link, index) in leftLinks" :key="index">
        <router-link
          :to="link.to"
          class="m-nav-link"
          active-class="active"
        ><span>{{ link.title }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LeftMenu',
  computed: {
    ...mapGetters({
      leftLinks: 'layout/getLeftLinks'
    })
  }
}
</script>

<style lang="scss" scoped>
.left-menu-inner {
  width: 100%;
  margin-top: 80px;
  position: relative;
}

.m-nav {
  list-style: none;
  margin: 0;
  padding: 0;

  .m-nav-item {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  .m-nav-link {
    display: block;
    font-weight: 700;
    font-size: 14px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    color: #000;
    border-radius: 4px;
    transition: background-color 85ms ease;
    text-decoration: none;

    &:hover {
      background-color: rgba(9,30,66,.08);
      text-decoration: none;
    }
  }

  .m-nav-link.active {
    background-color: #e4f0f6;
    color: #0079bf;
  }
}
</style>
