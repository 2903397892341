<template>
  <nav class="d-flex header-wrapper justify-content-between align-items-center fixed-top">

    <div>
      <div
        class="px-3 py-2 menu-opener-button-wrapper"
        @click="$store.dispatch('layout/toogleLeftMenu')">
        <font-awesome-icon
          icon="bars"
          class="text-white font-weight-light pointer menu-opener-button"
        />
      </div>
    </div>

    <div>
      <router-link to="/app" class="logo">💰</router-link>
    </div>

    <div class="name-dropdown-wrapper">
      <b-dropdown no-caret variant="link">
        <template #button-content>
          <div
            class="name-wrapper px-3 d-flex align-items-center"
          >
            <span class="name align-middle">{{ username }}</span>
          </div>
        </template>
        <b-dropdown-item>
          <router-link
            :to="{ name: 'admin' }"
            v-if="isCurrentUserAdmin"
          >
            <li>
              Админка
            </li>
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item>
          <router-link
            :to="{ name: 'logout' }"
            v-if="isLoggedIn"
          >
            <li>
              Выход
            </li>
          </router-link>
        </b-dropdown-item>
      </b-dropdown>
    </div>

  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MHeader',
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      username: 'auth/currentUserName',
      leftLinks: 'header/getLeftLinks',
      isLoggedIn: 'auth/isLoggedIn',
      isCurrentUserAdmin: 'auth/isCurrentUserAdmin',
      isLeftMenuOpened: 'layout/isLeftMenuOpened'
    })
  }
}
</script>

<style lang="scss">
.name-dropdown-wrapper {
  .btn-link {
    &:focus {
      text-decoration: none !important;
    }
    padding: 0;
  }
  a {
    text-decoration: none!important;
    color: #212529;

    &:active {
      background-color: inherit;
    }
  }
  .dropdown-menu {
    margin-right: 1rem;
    margin-top: 0.25rem;
  }
}
</style>

<style scoped lang="scss">
.header-wrapper {
  background-color: #bd3586;
  z-index: 1000;
  height: 40px;
}

.logo {
  font-size: 1.1rem;
  font-weight: 600;
  color: #ccc;
  text-decoration: none;

  &:visited {
    color: #ccc;
  }

  &:active, &:hover {
    color: #eee;
    text-decoration: none;
  }
}

.nav-link {
  padding: 0 0.7rem !important;
  color: #d5d5d5;
  font-weight: 500;

  &:first-child {
    padding-left: 0!important;
  }

  &:hover {
    color: #fff;
  }
}

.name-wrapper {
  height: 40px;
  color: #fff;
  margin-right: 3rem;

  &:hover {
    cursor: pointer;
    background-color: #a32d73;
  }
}

.menu-opener-button-wrapper {
  cursor: pointer;
  margin-left: 3rem;

  &:hover {
    background-color: #a32d73;
  }
}

@media (max-width: 991.98px) {
  .menu-opener-button-wrapper {
    margin-left: 1rem;
  }
  .name-wrapper {
    margin-right: 1rem;
  }
}
</style>
